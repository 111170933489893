import { Component, OnInit } from '@angular/core';
import { GlobalServiceService } from '../global-service.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http } from '@angular/http';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-package-details',
  templateUrl: './package-details.component.html',
  styleUrls: ['./package-details.component.scss']
})
export class PackageDetailsComponent implements OnInit {

  constructor( public globalService: GlobalServiceService , private language: TranslateService, private http: Http, private router: Router) { }

  ngOnInit() {
  }

  makeReservationRequest(formData){
    this.http.post("https://35.229.33.107:445/api/reservation-requests",{"customerName":formData.customerName,"customerEmail":formData.customerEmail,"customerMobile":formData.customerPhoneNumber,"packageCode":this.globalService.selectedPackage.code,"status":"REQUESTED_SUBMITTED","travelPackage":{"id":this.globalService.selectedPackage.id}}).subscribe(data => {
      console.log("success");
      this.router.navigate(['/home']);
    }, error => {
      console.log(error);
    });
}
}
