import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalServiceService } from '../global-service.service';
import { Http } from '@angular/http';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements AfterViewInit {
  packagesCatList = ['domestic', 'foreign', 'arab', 'religious', 'all'];
  fileName = 'assets/data/packagesObject.json';
  packagesList = [];

  packagesListFiltered: any;
  clicked = 'religious';
  constructor(private language: TranslateService,
    private globalService: GlobalServiceService,
    private http: Http,
    private route: ActivatedRoute,
    private router: Router,
    private _sanitizer: DomSanitizer) { }


  ngAfterViewInit() {
    // this.readJsonFile(this.fileName);
    this.getTravelPackages(this.clicked);
  }
  readJsonFile(jsonFileName) {
    this.http.get(jsonFileName).subscribe(data => {
      this.packagesList = data.json();
      this.selectedCategory(this.clicked);
    }, error => {
      console.log(error);
    });
  }

  selectedCategory(category: string) {
    this.packagesListFiltered = [];
    this.getTravelPackages(category);
  }

  getTravelPackages(selectedCategory){
    var filterString ="";
    if(selectedCategory == 'domestic'){
      filterString = "?packageType.equals=DOMESTIC";
    }else if (selectedCategory == 'foreign'){
      filterString = "?packageType.equals=INTERNATIONAL";
    }else if (selectedCategory == 'religious'){
      filterString = "?packageType.equals=RELIGIOUS";
    }else if (selectedCategory == 'arab'){
      filterString = "?packageType.equals=ARAB";
    }
    this.http.get("https://35.229.33.107:445/api/travel-packages"+filterString).subscribe(data => {
      console.log(data.json());
      this.packagesListFiltered = data.json();
    }, error => {
      console.log(error);
    });
  }

  santizeImageUrl(imageContentType,imageContent){
    return this._sanitizer.bypassSecurityTrustResourceUrl('data:'+imageContentType+';base64,'+ imageContent);
  }

  goToDetailsPage(item) {
    this.globalService.selectedPackage = item;
    if (this.router.url === '/package-details') {
      window.scrollTo(0, 0);
    } else {
      this.router.navigate(['/package-details']);
    }

  }

}
